<template>
  <div id="app">
    <AppLayout>
        <router-view/>
    </AppLayout>
  </div>
</template>

<script>
import AppLayout from '@/components/AppLayout.vue'
// import {mapState} from 'vuex'

export default {
  name: 'app',
  components:{
    AppLayout
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
  width: 100%;
  background: rgb(87,117,222);
}
*{
  margin: 0;
  padding: 0;
}
html, body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background: var(--bg-default);
}
:root{
  --primary: #002250;
}
</style>
