import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName "home" */ '@/landing/Land.vue'),
    meta: { layout: "NoLayout" }
  },
  {
    path: '/infographics',
    name: 'Infographics',
    component: () => import(/* webpackChunkName "home" */ '@/views/Infographics.vue'),
    // meta: { layout: "NoLayout" }
  },
  {
    path: '/smart-contract-saturday',
    name: 'SmartContractSaturday',
    component: () => import(/* webpackChunkName "home" */ '@/views/SmartContractSaturday.vue'),
  },
  {
    path: '/infographics/:id',
    name: 'Infographic',
    component: () => import(/* webpackChunkName "home" */ '@/views/Infographic.vue'),
    // meta: { layout: "NoLayout" }
  },
  {
    path: '/christmas/:person',
    name: 'Christmas',
    component: () => import(/* webpackChunkName "home" */ '@/views/Christmas.vue'),
    meta: { layout: "NoLayout" }
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: () => import(/* webpackChunkName "home" */ '@/views/Home.vue'),
  // },
  // {
  //   path: '/portfolio-sim',
  //   name: 'PortfolioSim',
  //   component: () => import(/* webpackChunkName "home" */ '@/views/PortfolioSim.vue'),
  // },
  // {
  //   path: '/cms-portal',
  //   name: 'CMSPortal',
  //   component: () => import(/* webpackChunkName "home" */ '@/views/CMSPortal.vue'),
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/podcasts',
    name: 'Podcasts',
    component: () => import('../views/Podcasts.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
