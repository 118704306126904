<template>
  <div id="">
      <slot />
  </div>
</template>
<script>
export default{
components:{
},
}
</script>
<style scoped>
</style>
