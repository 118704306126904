<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
// import Default from '@/components/layouts/Default'
import NoLayout from '@/components/layouts/NoLayout'
import { shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'
export default {
  name: 'AppLayout',
  setup () {
    const layout = shallowRef(NoLayout)
    const route = useRoute()
    watch(
      () => route.meta,
      async meta => {
        try {
          const component = await require(`@/components/layouts/${meta.layout}.vue`)
          layout.value = component?.default || NoLayout
        } catch (e) {
          layout.value = NoLayout
        }
      },
    )
    return { layout }
  }
}
</script>
