<template>
<div id="Header">
  <div class="left-side">
    <router-link :to="{name: 'Home'}"><img src="@/assets/logo.png" class="brand-logo"/></router-link>
  </div>
  <div class="mid-side">
    <ul class="links">
      <li v-for="(nm, idx) in nav_menu" :key="idx" @click="goto(nm.route)">
        <p>{{nm.name}}</p>
      </li>
    </ul>
  </div>
  <div class="right-side">
    <span >
        <router-link :to="{name:'Contact'}" class=" primary-btn2">
          <div class="gradient-text"  >Contact</div>
        </router-link>
    </span>
  </div>
</div>
</template>
<script>
// import {mapState, mapGetters} from 'vuex'

export default {
  name: 'Header',
  components: {

  },
  data(){
    return{
      nav_menu:[
        {name:'Infographics', route: '/infographics'},
        // {name:'Smart Contract Saturday', route: '/smart-contract-saturday'},
        // {name:'Articles', route: '/articles'},
        // {name:'Projects', route: '/projects'},
        // {name:'Research', route: '/reserach'},
      ]
    }
  },
  computed:{
    // ...mapGetters([
    //   'isAuthenthicated'
    // ]),
  },
  methods:{
    goto(route){
      if(route != ''){
        window.open(route, '_self')
      }
    }
  }
}
</script>
<style scoped>
#Header{
  width: 100%;
  height: 6rem;
  padding: 0 1rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items:center;
  background: rgb(87,117,222);
  transition: 1s;
}
a{
  text-decoration: none;
}
.left-side{
  display: flex;
  justify-content: flex-start;
  align-items:center;
}
.brand-logo{
  width: 3rem;
}
.links {
  list-style-type: none;
  margin: 0 2rem;
  padding: 0;
  font-size: 1rem;
  overflow: hidden;
  transition: 1s;
  color: #fff;
}
.right-side{

}
.right-side-btn-group{
  display: flex;
  justify-content: space-evenly;
  align-items:center
}
.primary-btn2{
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items:center;
  background-color: var(--primary);
  padding: .5rem 1rem;
  height:100%;
  font-size: .7rem;
  margin:auto .1rem;
  border-radius: 7px;
  transition: 1s;
  box-shadow: 0 0 0 0 rgba(#222, .5);
  -webkit-box-shadow: 0 0 0 0 rgba(#222, .5);
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 2s infinite;
}
.primary-btn2:hover{
  -webkit-animation: none;
  animation: none;
}
.gradient-text {
  background-color: #fff;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
li {
  float: left;
}
li:hover {opacity: .7}
li p {
  display: block;
  color: #fff;
  cursor:pointer;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

li a:hover {
  opacity: .7;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@media only screen and (min-width: 600px) {
  .primary-btn2{
    padding: 1rem 2rem;
    margin:auto .1rem;
  }
}

@media only screen and (max-width: 600px) {
li p {
    padding: 5px;
}
.btn{
  display: flex;
  justify-content: center;
  font-size: .5em;
  padding: .7rem 1rem ;
  margin: .3rem;
  text-align: center;
  width: 4rem;
}
.right-side-btn-group{

}
}
@media only screen and (max-width: 400px) {
  li{
    /* font-size: 10px; */
  }
  .primary-btn2{
    font-size: 6px;
    border-radius: 5px;
  }
}
</style>
