<template>
  <div id="">
    <Header />
      <slot />
    <Footer />
  </div>
</template>
<script>
import Header from "./sections/Header.vue";
import Footer from "./sections/Footer.vue";
export default{
components:{
  Header,
  Footer,
},
}
</script>
<style scoped>
</style>
